import { memo } from "react";
import {
  Nav,
  Navbar,
  Popover,
  OverlayTrigger,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faBell,
  faGrip,
  faToolbox,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";

import { getUserInfo, logout } from "services";

// import Logos
import Logo from "assets/images/logos/esma.png";
import ProfileIconDefault from "assets/images/defaults/user.png";
import SelectorLanguages from "./SelectorLanguages";

const Header = ({ t }) => {
  const profile = getUserInfo();
  const placement = "bottom";
  console.log(profile);
  const sizeIcons = 20;
  const smallSizeIcon = 16;

  return (
    <div>
      <section className="flex-container">
        <Navbar bg="light" expand="lg">
          <div className="menu menu-start">
            <Navbar.Brand className="text-start">
              <Link to="/">
                <img
                  src={Logo}
                  className="logoconvert"
                  style={{ width: 140, marginTop: "-5px", padding: "1rem" }}
                  alt="logo-bands"
                />
              </Link>
            </Navbar.Brand>
          </div>
          <div className="menu menu-center">
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link href="/">
                  <FontAwesomeIcon
                    style={{ fontSize: sizeIcons }}
                    icon={faToolbox}
                  />
                  <div className="bar-action" />
                </Nav.Link>
                <Nav.Link href="/layouts">
                  <FontAwesomeIcon
                    style={{ fontSize: sizeIcons }}
                    icon={faGauge}
                  />
                  <div className="bar-action" />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
          <div className="menu menu-end">
            <Navbar.Collapse id="profile" className="collapse-customs">
              <Nav className="ms-auto">
                <Link className="notify-icon">
                  <FontAwesomeIcon
                    style={{ fontSize: smallSizeIcon }}
                    icon={faBell}
                  />
                </Link>
                <Link className="menu-grid">
                  <FontAwesomeIcon
                    style={{ fontSize: smallSizeIcon }}
                    icon={faGrip}
                  />
                </Link>

                <OverlayTrigger
                  trigger="click"
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover id={`${placement}`} style={{ width: "350px" }}>
                      <Popover.Header as="h3" className="bg-dark py-3 px-3 ">
                        <Row>
                          <Col xs={2}>
                            <div
                              className="user-profile-icon"
                              style={{
                                backgroundImage: `url(${
                                  profile?.profileImage ?? ProfileIconDefault
                                })`,
                              }}
                            ></div>
                          </Col>
                          <Col>
                            {`${profile?.firstName} ${
                              profile?.middleName ?? ""
                            } ${profile?.lastName}`}
                            <br />
                            <small className="small text-warning">
                              {profile?.email}
                            </small>
                          </Col>
                        </Row>
                      </Popover.Header>
                      <Popover.Body>
                        <SelectorLanguages t={t} />
                        <Button
                          variant="outline-secondary"
                          className="w-100"
                          onClick={() => logout()}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: sizeIcons }}
                            icon={faSignOut}
                            className="me-3 mt-1"
                          />
                          Sign-out
                        </Button>{" "}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div
                    className="user-profile-icon"
                    style={{
                      backgroundImage: `url(${
                        profile?.profileImage ?? ProfileIconDefault
                      })`,
                    }}
                  ></div>
                </OverlayTrigger>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </section>
    </div>
  );
};

export default memo(Header);
