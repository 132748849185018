import { memo } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { HelpCircle } from "react-feather";

const MenuConfigs = ({ icon, title, desc, link }) => {
  return (
    <Card className="card-menu-lists mb-3">
      <div className="display-flex">
        <div className="inline-icons">
          {icon ? icon : <HelpCircle size={42} className="item-center" />}
        </div>
        <div className="inline-header">
          <div className="inline-desc">
            <Link to={`${link ?? "#"}`}>
              <h1 className="upper">{title ? title : "Header"}</h1>
            </Link>
            <p>{desc ? desc : ""}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(MenuConfigs);
