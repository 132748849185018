import { memo } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

//:: Import Page Components :://
import NotFound from "components/NotFound";
import Dashboards from "pages/Dashboards";
import AccountManagement from "pages/Accounts/Manages";
import AccountMaintain from "pages/Accounts/Maintains";
import PlantManagement from "pages/Plants/Manages";
import PlantMaintain from "pages/Plants/Maintains";
import OrganizationManagement from "pages/Organization/Manages";
import OrganizationMaintain from "pages/Organization/Maintains";
import PermissionManagement from "pages/Permissions/Manages";
import PermissionMaintain from "pages/Permissions/Maintains";
import MenuManages from "pages/Menus/Manages";
import MenuMaintains from "pages/Menus/Maintains";
import ProjectManagement from "pages/Projects/Manages";
import ProjectMaintain from "pages/Projects/Maintains";
import Finances from "pages/Finances";
import Emails from "pages/Emails";

const MainLayouts = ({ translates }) => {
  return (
    <div>
      <Header t={translates} />
      <Container className="bg-main">
        <Routes>
          <Route exact path="/" element={<Dashboards t={translates} />} />
          {/** Account Routes **/}
          <Route
            exact
            path="/accounts"
            element={<AccountManagement t={translates} />}
          />
          <Route
            exact
            path="/accounts/:hashUsername"
            element={<AccountMaintain />}
          />
          {/** End Accout Routes **/}

          {/** Menus Routes **/}
          <Route exact path="/menus" element={<MenuManages />} />
          <Route exact path="/menus/:slug" element={<MenuMaintains />} />
          {/** End Menus Routes **/}

          {/** Plants Routes **/}
          <Route exact path="/plants" element={<PlantManagement />} />
          <Route exact path="/plants/:slug" element={<PlantMaintain />} />
          <Route
            exact
            path="/organizations"
            element={<OrganizationManagement />}
          />
          <Route
            exact
            path="/organizations/:slug"
            element={<OrganizationMaintain />}
          />
          <Route
            exact
            path="/plant/projects/maintain/:slug/:proslug"
            element={<ProjectMaintain />}
          />
          <Route
            exact
            path="/plant/projects/:slug"
            element={<ProjectManagement />}
          />
          {/** End Plants Routes **/}

          {/** Permissions Routes **/}
          <Route exact path="/permissions" element={<PermissionManagement />} />
          <Route
            exact
            path="/permissions/:slug"
            element={<PermissionMaintain />}
          />
          {/** End Permissions Routes **/}

          {/** Emails */}

          {/** End Emails */}
          <Route exact path="/emails" element={<Emails />} />
          {
            /** Finance Fuctional */
            <Route exact path="/finances" element={<Finances />}></Route>
          }

          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

export default memo(MainLayouts);
