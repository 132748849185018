import api from "./api";

export const FINANCE_URL = "/finances";

export const getFinances = async (filter) => {
  return api
    .get(FINANCE_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const approveRequest = async (data) => {
  return api.post(`${FINANCE_URL}/approve`, data).then((res) => res.data);
};

export const uploadDocumentForm = async (data) => {
  return api.post(`${FINANCE_URL}/uploads`, data).then((res) => res.data);
};

export const approveSlips = async (data) => {
  return api.post(`${FINANCE_URL}/approve/slips`, data).then((res) => res.data);
};
