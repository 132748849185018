import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs";
import FormMaintain from "./FormMaintain";

import {
  postStorePermission,
  getEditPermission,
  putUpdatePermission,
} from "services";

const MaintainPermissions = () => {
  const navigator = useNavigate();
  const { slug } = useParams();
  let eventTypes = slug === "create" ? "create" : "edit";
  const [dataPermission, setDataPermission] = useState({});

  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "permissions",
      path: "/permissions",
      active: false,
    },
    {
      lable: "maintain",
      active: true,
    },
  ];

  const handleSubmit = async (values) => {
    console.log(values);
    if (eventTypes === "create") {
      const { success } = await postStorePermission(values);
      if (success) {
        navigator("/permissions");
      }
    } else {
      const { success } = await putUpdatePermission(slug, values);
      if (success) {
        navigator("/permissions");
      }
    }
  };

  const getDataEditPermission = async () => {
    const { data } = await getEditPermission(slug);
    console.log(data);
    setDataPermission(data?.permission);
  };

  useEffect(() => {
    if (eventTypes !== "create") {
      getDataEditPermission();
    }
  }, []);

  return (
    <section className="maintain">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page">Permission Maintain</h1>
      <FormMaintain onFinish={handleSubmit} initialData={dataPermission} />
    </section>
  );
};

export default memo(MaintainPermissions);
