export const userTypes = [
  { label: "Admin", value: "ADM" },
  { label: "Staff", value: "STF" },
  { label: "Customer", value: "CTM" },
];

export const unitPowerTypes = [
  { label: "Kilowatt Hour", value: "kWh" },
  { label: "Megawatt Hour", value: "mWh" },
];

export const statusDefaults = [
  { label: "Active", value: "ACT" },
  { label: "Inactive", value: "IAT" },
];

export const typeMailDefaults = [
  { label: "TO", value: "to" },
  { label: "CC", value: "cc" },
  { label: "BCC", value: "bcc" },
];

export const departmentSets = [
  { label: "Engineer", value: "ENG" },
  { label: "Account", value: "ACC" },
  { label: "Sale", value: "SAL" },
  { label: "Finance", value: "FIN" },
];
