import { memo, useState } from "react";
import ListDetections from "components/ListDetections";
import { useMount } from "hooks";
import { getProjectTickets } from "services";

const Tickets = ({ projectId, plantSlug }) => {
  const [tickets, setTickets] = useState([]);

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getProjectTickets(plantSlug, projectId);

      setTickets(results?.tickets);
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  console.log(plantSlug);
  console.log(projectId);
  return (
    <div>
      <ListDetections data={tickets} />
    </div>
  );
};

export default memo(Tickets);
