import { memo, useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { uploadDocumentForm } from "services";

const UploadFileForm = ({ data, onClose }) => {
  const [optionTypeFile, setOptionTypeFile] = useState([]);
  const [typeDoc, setTypeDoc] = useState(undefined);

  useEffect(() => {
    if (data?.documentType === "QUA") {
      setOptionTypeFile([
        {
          value: "QUA",
          label: "Quotations",
        },
      ]);
    }

    if (data?.documentType === "WPO") {
      setOptionTypeFile([
        {
          value: "INV",
          label: "Invoice / Bill slip",
        },
        {
          value: "QUA",
          label: "Quotations",
        },
      ]);
    }

    if (data?.documentType === "WSL") {
      setOptionTypeFile([
        {
          value: "REC",
          label: "Receipt / Tax Invoice",
        },
        {
          value: "INV",
          label: "Invoice / Bill slip",
        },
        {
          value: "QUA",
          label: "Quotations",
        },
      ]);
    }
  }, [data]);

  /** Upload File function */
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const MAX_COUNT = 1;

  const handleSelTypeDoc = (e) => {
    setTypeDoc(e?.value);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleEventUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const onSubmitUploadFile = async () => {
    const formDatas = new FormData();
    if (uploadedFiles.length > 0) {
      uploadedFiles.map((fi) => {
        formDatas.append("files", fi);
      });

      if (!typeDoc) {
        return false;
      }

      formDatas.append(
        "payloads",
        JSON.stringify({ ...data, documentType: typeDoc })
      );

      const { success } = await uploadDocumentForm(formDatas);

      if (success) {
        Swal.fire({
          title: "Completed !",
          html: "Your send email is successfully.",
          icon: "success",
        });
        onClose();
      }
    } else {
      return false;
    }
  };

  return (
    <Row>
      <Col sm={12} className="bor-sol-buttom mb-3">
        <h1 className="title-modal">Upload Files</h1>
      </Col>

      <Col sm={12} className="mb-3">
        <h5 className="title-modal">Types Files</h5>
        <Select
          className="custom-select"
          options={optionTypeFile}
          onChange={handleSelTypeDoc}
        />
      </Col>
      {typeDoc && (
        <Col xs={12} className="mb-3">
          <Form.Group controlId="formFileSingle" className="mb-3">
            <Form.Control
              type="file"
              accept="application/pdf, image/png, image/jpg, image/jpeg"
              onChange={handleEventUpload}
            />
            <Form.Label className="text-white mt-2">
              This file will send email to stakeholders.
            </Form.Label>
          </Form.Group>
          <div className="text-end">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={onSubmitUploadFile}
            >
              Upload
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default memo(UploadFileForm);
