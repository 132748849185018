import { memo } from "react";
import { Row, Col, Button } from "react-bootstrap";

// :: Import Image from categories :: //
import NotFoundCharacter from "assets/images/defaults/notfound.png";

const NotFounds = () => {
  return (
    <section className="page-notfound">
      <Row className="mt-5">
        <Col lg={2} md={0} sm={0} xs={0}></Col>
        <Col lg={8} md={12} sm={12} xs={12} className="text-center">
          <h1 className="bigger-topic">OOP !</h1>
          <h1 className="bigger-number">404</h1>
          <p className="head-paragraph">Sorry, the page not found</p>
          <p className="desc-paragraph">
            The link you followed probably broken
          </p>
          <p className="desc-paragraph">or the page has been removed.</p>
          <div className="my-3">
            <Button variant="outline-primary" href="/">
              Homepage
            </Button>
          </div>
          <div className="animation-bounce bounce2">
            <img
              src={NotFoundCharacter}
              alt={`not-found-page`}
              className="charactor-notfound"
            />
          </div>
        </Col>
        <Col lg={2} md={0} sm={0} xs={0}></Col>
      </Row>
    </section>
  );
};

export default memo(NotFounds);
