import { memo, useEffect, useState } from "react";
import { useMount } from "hooks";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { Save } from "react-feather";
import { getMenus } from "services";

const FormMaintain = ({ onFinish, initialData }) => {
  const navigator = useNavigate();
  const [validated, setValidated] = useState(false);
  const [menus, setMenus] = useState({
    show: [],
    create: [],
    edit: [],
    delete: [],
  });
  const [menuData, setMenuData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      onFinish({ ...formDataObj, ...menus });
    }

    setValidated(true);
  };

  const handleMenus = (event, name, module) => {
    const dataSet = { ...menus };

    if (event.target?.checked) {
      // add menu allowed
      dataSet[module]?.push(name);
    } else {
      // del menu allowed
      const findIndex = dataSet[module]?.indexOf(name);
      dataSet[module]?.splice(findIndex, 1);
    }

    // console.log(dataSet);

    setMenus(dataSet);
  };

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getMenus();
      setMenuData(results?.menus);
    } catch (error) {
      navigator("/permissions");
    }
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    if (initialData) {
      setMenus({
        show: initialData?.show ?? [],
        create: initialData?.create ?? [],
        edit: initialData?.edit ?? [],
        delete: initialData?.delete ?? [],
      });
    }
  }, [menuData, initialData]);

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">permission name</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Permission Name"
                    aria-label="Permission Name"
                    name="permissionName"
                    defaultValue={initialData?.permissionName}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">description</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Description"
                    aria-label="Description"
                    as="textarea"
                    name="description"
                    defaultValue={initialData?.description}
                    rows={4}
                    required
                  />
                </InputGroup>
              </Col>

              <Col lg={12}>
                <p className="text-topic-big mb-1">Menu lists</p>
              </Col>
              {menuData?.map((mnd, key) => {
                return (
                  <Row className="mb-2" key={key}>
                    <Col sm={4}>
                      <p className="text-topic mb-0">{mnd?.menuName}</p>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Form.Check
                        type={`checkbox`}
                        label={`Show`}
                        onChange={(event) =>
                          handleMenus(event, mnd?.id, "show")
                        }
                        checked={
                          initialData?.show?.indexOf(mnd?.id) > -1
                            ? true
                            : false
                        }
                      />
                    </Col>
                    <Col xs={6} sm={2}>
                      <Form.Check
                        type={`checkbox`}
                        label={`Create`}
                        onChange={(event) =>
                          handleMenus(event, mnd?.id, "create")
                        }
                        checked={
                          initialData?.create?.indexOf(mnd?.id) > -1
                            ? true
                            : false
                        }
                      />
                    </Col>
                    <Col xs={6} sm={2}>
                      <Form.Check
                        type={`checkbox`}
                        label={`Edit`}
                        onChange={(event) =>
                          handleMenus(event, mnd?.id, "edit")
                        }
                        checked={
                          initialData?.edit?.indexOf(mnd?.id) > -1
                            ? true
                            : false
                        }
                      />
                    </Col>
                    <Col xs={6} sm={2}>
                      <Form.Check
                        type={`checkbox`}
                        label={`Delete`}
                        onChange={(event) =>
                          handleMenus(event, mnd?.id, "delete")
                        }
                        checked={
                          initialData?.delete?.indexOf(mnd?.id) > -1
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                );
              })}

              <Col sm={12} className="text-end">
                <Button variant="outline-primary" type="submit">
                  <Save size={18} className="me-2" />
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(FormMaintain);
