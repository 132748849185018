import { memo } from "react";
import { Breadcrumb } from "react-bootstrap";

const Breadcrumbs = ({ lists }) => {
  return (
    <div>
      <Breadcrumb>
        {lists?.map((res, k) => {
          if (res?.active) {
            return (
              <Breadcrumb.Item active key={k}>
                {res?.lable}
              </Breadcrumb.Item>
            );
          } else {
            return (
              <Breadcrumb.Item href={res?.path ?? "/"} key={k}>
                {res?.lable}
              </Breadcrumb.Item>
            );
          }
        })}
      </Breadcrumb>
    </div>
  );
};

export default memo(Breadcrumbs);
