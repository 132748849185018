import api from "../services/api";

export const PROJECT_URL = "/projects";

export const getProjects = async (slug, filter) => {
  return api
    .get(`${PROJECT_URL}/${slug}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getEditProject = async (slug) => {
  // return api.get(`${PROJECT_URL}/${slug}`).then((res) => res.data);
};

export const postStoreProject = async (slug, datas) => {
  console.log(slug, datas);
  return api.post(`${PROJECT_URL}/${slug}`, datas).then((res) => res.data);
};
