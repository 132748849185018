import api from "./api";

export const MENU_URL = "/menu-sets";

export const getMenus = async (filter) => {
  return api
    .get(MENU_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const postStoreMenu = async (datas) => {
  return api.post(MENU_URL, datas).then((res) => res.data);
};

export const getEditMenu = async (slug) => {
  return api.get(`${MENU_URL}/${slug}`).then((res) => res.data);
};

export const postUpdateMenu = async (slug, data) => {
  return api.put(`${MENU_URL}/${slug}`, data).then((res) => res.data);
};
