import api from "../services/api";

export const PLANT_URL = "/plants";

export const getPlants = async (filter) => {
  return api
    .get(PLANT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getEditPlant = async (slug) => {
  return api.get(`${PLANT_URL}/${slug}`).then((res) => res.data);
};

export const postStorePlant = async (datas) => {
  return api.post(PLANT_URL, datas).then((res) => res.data);
};

export const putUpdatePlant = async (id, data) => {
  return api.put(`${PLANT_URL}/${id}`, data).then((res) => res.data);
};
