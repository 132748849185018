import { memo, useState } from "react";
import { useMount } from "hooks";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { getPlants, setAccountToPlant } from "services";

const FormSetPlant = ({ data, closed }) => {
  const [dataPlant, setDataPlant] = useState([]);
  const [plant, setPlant] = useState([]);
  console.log(data);
  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getPlants();
      const dataSets = [];
      results?.plants?.map((res, k) => {
        dataSets.push({
          key: k,
          value: res?.id,
          label: res?.plantName,
        });
      });

      setDataPlant(dataSets);
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  const handleSelect = (event) => {
    setPlant(event);
  };

  const submitPlantSet = async () => {
    if (plant) {
      const datas = { plantId: plant?.value, userId: data?.id };
      const { success } = await setAccountToPlant(datas);

      if (success) {
        closed();
      }
    }

    return false;
  };

  console.log(plant);

  return (
    <Row>
      <Col sm={12} className="bor-sol-buttom">
        <h1 className="title-modal">Setting Plant</h1>
      </Col>
      <Col sm={12} className="w-100 my-3">
        <Select
          options={dataPlant}
          className="custom-select"
          onChange={handleSelect}
          defaultValue={plant[0]}
        />
      </Col>
      <Col sm={12} className="text-end">
        <Button
          variant="outline-primary"
          size="sm"
          className="me-3"
          onClick={submitPlantSet}
        >
          Save
        </Button>
        <Button variant="outline-danger" size="sm" onClick={() => closed()}>
          Close
        </Button>
      </Col>
    </Row>
  );
};

export default memo(FormSetPlant);
