import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import { Tag } from "react-feather";

const ListDetections = ({ data }) => {
  let total = 0;
  const statusConvert = (status) => {
    if (status === "WAITING") {
      return "Waiting";
    } else if (status === "PENDING") {
      return "On Process";
    } else if (status === "PAID") {
      return "Paid";
    }
  };

  const classStatus = (status) => {
    if (status === "WAITING") {
      return "danger";
    } else if (status === "PENDING") {
      return "warning";
    } else if (status === "PAID") {
      return "success";
    }
  };
  return (
    <Row>
      <Col xs={12}>
        {data.length === 0 && (
          <p className="text-danger text-center">No data ticket</p>
        )}
        {data?.map((tk, k) => {
          if (tk?.status !== "PAID") {
            total = total += tk?.valueLoss;
          }

          return (
            <div className="card card-lists mb-2" key={k}>
              <Row>
                <Col xs={1} className="text-center">
                  <Tag size={16} />
                </Col>
                <Col>{tk?.stringName}</Col>
                <Col>{tk?.valueLoss} ฿</Col>
                <Col className={`text-center text-${classStatus(tk?.status)}`}>
                  {statusConvert(tk?.status)}
                </Col>
              </Row>
            </div>
          );
        })}
      </Col>
      <Row>
        <Col xs={12} className="text-end">
          <hr />
          <h5>Total</h5>
          <h3 className="text-danger">{total.toFixed(2)} ฿</h3>
        </Col>
      </Row>
    </Row>
  );
};

export default memo(ListDetections);
