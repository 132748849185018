import { memo, useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, Image, InputGroup } from "react-bootstrap";
import { Save } from "react-feather";

// :: Images :: //
import BlankImage from "assets/images/defaults/blank_500x500.png";

const FormMaintain = ({ onFinish, initial }) => {
  const [validated, setValidated] = useState(false);
  const hiddenFileInput = useRef(null);
  const [logo, setLogo] = useState("");
  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });

    setLogo(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      onFinish({ ...formDataObj, logo });
    }

    setValidated(true);
  };

  useEffect(() => {
    if (initial) {
      setLogo(initial?.data?.logo);
    }
  }, [initial]);

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={3} lg={3} sm={12} xs={12}>
            <Row>
              <Col sm={12}>
                <p className="text-topic mb-1"> picture </p>
                <Image
                  className="w-100 profile-round mb-3"
                  src={logo || BlankImage}
                />
                <Button
                  variant="outline-primary w-100"
                  onClick={handleClickUpload}
                >
                  Upload Picture
                </Button>
                <Form.Control
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChangeImage}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </Col>
            </Row>
          </Col>
          <Col xl={9} lg={9} sm={12} xs={12}>
            <Row>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">Organization Name</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Organization Name"
                    aria-label="Organization Name"
                    name="orgName"
                    defaultValue={initial?.data?.orgName}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">address</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Address"
                    aria-label="Address"
                    as="textarea"
                    name="address"
                    defaultValue={initial?.data?.address}
                    rows={4}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email one</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="email"
                    defaultValue={initial?.data?.email}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email two</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="emailtwo"
                    defaultValue={initial?.data?.emailtwo}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email three</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="emailthree"
                    defaultValue={initial?.data?.emailthree}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email four</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="emailfour"
                    defaultValue={initial?.data?.emailfour}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email five</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="emailfive"
                    defaultValue={initial?.data?.emailfive}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">email six</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    name="emailsix"
                    defaultValue={initial?.data?.emailsix}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Telephone</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Telephone"
                    aria-label="Telephone"
                    name="phoneNumber"
                    defaultValue={initial?.data?.phoneNumber}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Fax</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Fax Number"
                    aria-label="Fax Number"
                    name="fax"
                    defaultValue={initial?.data?.fax}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Contact Number</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Contact Number"
                    aria-label="Contact Number"
                    name="contactNumber"
                    defaultValue={initial?.data?.contactNumber}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Tax ID</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Tax ID"
                    aria-label="Tax ID"
                    name="taxCode"
                    defaultValue={initial?.data?.taxCode}
                    required
                  />
                </InputGroup>
              </Col>
              <Col sm={12} className="text-end">
                <Button variant="outline-primary" type="submit">
                  <Save size={18} className="me-2" />
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(FormMaintain);
