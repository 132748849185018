import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination, Modal } from "react-bootstrap";
import { useMount } from "hooks";
import { Trash } from "react-feather";
import moment from "moment";
import Swal from "sweetalert2";

import { getEmails, createEmail, desTroyEmails } from "services";

// :: Component Customs :: //
import Breadcrumbs from "components/Breadcrumbs";
import DotStatus from "components/DotStatus";

import FormCreateEmail from "./FormCreateEmail";

const Emails = () => {
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "emails",
      active: true,
    },
  ];

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [emailers, setEmailers] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  const [modalEmail, setModalEmail] = useState(false);

  // Pagination Functions //
  const handleChangePage = (number) => {
    if (number <= 3) {
      setEmailers({
        ...emailers,
        number: 1,
        active: number,
      });
    } else if (number === emailers?.pagination?.lastPage - 1) {
      setEmailers({
        ...emailers,
        number: number - 3,
        active: number,
      });
    } else if (number === emailers?.pagination?.lastPage) {
      setEmailers({
        ...emailers,
        number: number - 4,
        active: number,
      });
    } else {
      setEmailers({
        ...emailers,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (emailers?.active >= emailers?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(emailers?.active + 1);
  };

  const handleChangePrev = () => {
    if (emailers?.active > 0) {
      if (emailers?.active === 1) {
        handleChangePage(emailers?.active);
      } else {
        handleChangePage(emailers?.active - 1);
      }
    }
  };

  const handleLastPage = () => {
    handleChangePage(emailers?.pagination?.lastPage);
  };
  // End Pagination Function //

  const items = [];

  for (
    let number = emailers?.number;
    number <=
    (emailers?.pagination?.lastPage < 5
      ? emailers?.pagination?.lastPage
      : emailers?.number + 4);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === emailers?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getEmails();

      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      setEmailers({
        ...emailers,
        data: results?.emailers,
        pagination: {
          ...emailers.paginate,
          current,
          pageSize,
          total,
          lastPage,
        },
      });
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [emailers?.pagination?.lastPage]);

  const handleClose = () => {
    setModalEmail(false);
  };

  // convert Status Value
  const handleConvertStatus = (str) => {
    if (str === "ACT") {
      return "Active";
    } else if (str === "PDI") {
      return "Pendding";
    } else {
      return "Inactive";
    }
  };

  const handleConvertColorStatus = (str) => {
    if (str === "ACT") {
      return "green";
    } else if (str === "PDI") {
      return "yellow";
    } else {
      return "red";
    }
  };

  const onSumitForm = async (value) => {
    const { success } = await createEmail(value);

    if (success) {
      fetchDataDefault();
      setModalEmail(false);
      Swal.fire({
        title: "Completed !",
        html: "Your create email is successfully.",
        icon: "success",
      });
    }
  };

  const getDeleteEmail = async (emailId) => {
    Swal.fire({
      title: "Are you sure delete this email?",
      text: "if you delete this please click confirm",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2b766c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { success } = await desTroyEmails(emailId);
        if (success) {
          Swal.fire("Deleted!", "This email is deleted.", "success");
          fetchDataDefault();
        } else {
          Swal.fire("Deleted!", "Someting wrong !", "warning");
          fetchDataDefault();
        }
      }
    });
  };

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Email Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setModalEmail(true)}
          >
            Create Emails
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Fullname</th>
                <th>Email</th>
                <th>Type</th>
                <th>Position</th>
                <th>Department</th>
                <th>Status</th>
                <th>Updated At</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {emailers?.data?.map((em, key) => {
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td>{em?.fullname}</td>
                    <td>{em?.email}</td>
                    <td>{em?.type}</td>
                    <td>{em?.position}</td>
                    <td>{em?.department}</td>
                    <td>
                      <DotStatus
                        value={handleConvertStatus(em?.status)}
                        color={handleConvertColorStatus(em?.status)}
                      />
                    </td>
                    <td>
                      {moment(em.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-center">
                      <Link onClick={() => getDeleteEmail(em?.id)}>
                        <Trash size={16} className="text-danger" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination size="sm" className="justify-content-end">
                <Pagination.First onClick={() => handleChangePage(1)} />
                <Pagination.Prev onClick={() => handleChangePrev()} />
                {items}
                <Pagination.Next onClick={() => handleChangeNext()} />
                <Pagination.Last onClick={() => handleLastPage()} />
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={modalEmail}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCreateEmail
            onSubmitForm={onSumitForm}
            onClose={() => handleClose()}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default memo(Emails);
