import { memo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Table, Button, Pagination, Modal } from "react-bootstrap";
import { useMount } from "hooks";
import { Edit, Trash, AlertTriangle } from "react-feather";
import moment from "moment";
import { base64 } from "utils";

import { getProjects } from "services";

// :: Component Customs :: //
import Breadcrumbs from "components/Breadcrumbs";
import DotStatus from "components/DotStatus";
import Tickets from "../Tickets";

const Projects = () => {
  const { slug } = useParams();
  const [showMd, setShowMd] = useState(false);
  const [projectId, setProjectId] = useState(undefined);
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "plants",
      path: "/plants",
      active: false,
    },
    {
      lable: "projects",
      active: true,
    },
  ];

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [projects, setProjects] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  // Pagination Functions //
  const handleChangePage = (number) => {
    if (number <= 3) {
      setProjects({
        ...projects,
        number: 1,
        active: number,
      });
    } else if (number === projects?.pagination?.lastPage - 1) {
      setProjects({
        ...projects,
        number: number - 3,
        active: number,
      });
    } else if (number === projects?.pagination?.lastPage) {
      setProjects({
        ...projects,
        number: number - 4,
        active: number,
      });
    } else {
      setProjects({
        ...projects,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (projects?.active >= projects?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(projects?.active + 1);
  };

  const handleChangePrev = () => {
    if (projects?.active > 0) {
      if (projects?.active === 1) {
        handleChangePage(projects?.active);
      } else {
        handleChangePage(projects?.active - 1);
      }
    }
  };

  const handleLastPage = () => {
    handleChangePage(projects?.pagination?.lastPage);
  };
  // End Pagination Function //

  const items = [];

  for (
    let number = projects?.number;
    number <=
    (projects?.pagination?.lastPage < 5
      ? projects?.pagination?.lastPage
      : projects?.number + 4);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === projects?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getProjects(slug);

      console.log(results);

      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      setProjects({
        ...projects,
        data: results?.projects,
        pagination: {
          ...projects.paginate,
          current,
          pageSize,
          total,
          lastPage,
        },
      });
    } catch (error) {}
  };

  const handleDetection = (projectId) => {
    setProjectId(projectId);
    setShowMd(true);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [projects?.pagination?.lastPage]);

  // convert Status Value
  const handleConvertStatus = (str) => {
    if (str === "ACT") {
      return "Active";
    } else if (str === "PDI") {
      return "Pendding";
    } else {
      return "Inactive";
    }
  };

  const handleConvertColorStatus = (str) => {
    if (str === "ACT") {
      return "green";
    } else if (str === "PDI") {
      return "yellow";
    } else {
      return "red";
    }
  };

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Project Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end">
          <Button
            variant="outline-primary"
            size="sm"
            href={`/plant/projects/maintain/${slug}/create`}
          >
            Create Project
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th className="text-center">Detections</th>
                <th>Project Name</th>
                <th>Status</th>
                <th>Updated At</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {projects?.data?.map((pj, key) => {
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td className="text-center">
                      <Link to="#" onClick={() => handleDetection(pj?.id)}>
                        <AlertTriangle className="text-warning" size={16} />
                      </Link>
                    </td>
                    <td>{pj?.projectName}</td>

                    <td>
                      <DotStatus
                        value={handleConvertStatus(pj?.status)}
                        color={handleConvertColorStatus(pj?.status)}
                      />
                    </td>
                    <td>
                      {moment(pj.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-center">
                      <Link to={`#`} className="me-3">
                        <Edit size={16} className="text-primary" />
                      </Link>
                      <Link to={`#`}>
                        <Trash size={16} className="text-danger" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {/*  */}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination size="sm" className="justify-content-end">
                <Pagination.First onClick={() => handleChangePage(1)} />
                <Pagination.Prev onClick={() => handleChangePrev()} />
                {items}
                <Pagination.Next onClick={() => handleChangeNext()} />
                <Pagination.Last onClick={() => handleLastPage()} />
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size="lg" show={showMd} onHide={() => setShowMd(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detections
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tickets plantSlug={slug} projectId={projectId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={() => setShowMd(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default memo(Projects);
