import { memo } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";

import { statusDefaults, typeMailDefaults, departmentSets } from "const";

const FormCreateEmail = ({ onSubmitForm, onClose }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    onSubmitForm(formDataObj);
  };
  return (
    <section>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Fullname <label className="text-danger">*</label>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Firstname Lastname"
                name="fullname"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Email address <label className="text-danger">*</label>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="name@enserv.co.th"
                name="email"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>Position</Form.Label>
              <Form.Control type="text" name="position" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Department <label className="text-danger">*</label>
              </Form.Label>
              <Select
                options={departmentSets}
                className="custom-select"
                name="department"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Type <label className="text-danger">*</label>
              </Form.Label>
              <Select
                options={typeMailDefaults}
                className="custom-select"
                name="type"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Status <label className="text-danger">*</label>
              </Form.Label>
              <Select
                options={statusDefaults}
                className="custom-select"
                name="status"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Modal.Footer>
          <Button variant="outline-success" size="sm" type="submit">
            Save
          </Button>
          <Button variant="outline-danger" size="sm" onClick={() => onClose()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </section>
  );
};

export default memo(FormCreateEmail);
