import { memo } from "react";
import { Row, Col } from "react-bootstrap";

// :: Import Custom Components :: //
import Breadcrumbs from "components/Breadcrumbs";
import MenuConfigs from "components/MenuConfigs";

// :: Import Custom Icons :: //
import {
  Briefcase,
  Users,
  Globe,
  Cpu,
  BarChart,
  Shield,
  Grid,
  Award,
  Mail,
} from "react-feather";

const Dashboards = ({ t }) => {
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: true,
    },
  ];
  const iconSize = 42;
  return (
    <section className="main-project">
      <Breadcrumbs lists={breadcrumbs} />
      <h3>{t("welcome")}</h3>
      <Row>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Briefcase size={iconSize} />}
            title={t("organization")}
            desc={
              "If you want to manage information about an organization related to your partners or customers."
            }
            link={`/organizations`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Users size={iconSize} />}
            title={t("accounts")}
            desc={
              "Information management about user account related to your employees or customers."
            }
            link={`/accounts`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Grid size={iconSize} />}
            title={t("menus")}
            desc={
              "Information management about user account related to your employees or customers."
            }
            link={`/menus`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Shield size={iconSize} />}
            title={t("permissions")}
            desc={
              "If you want to manage information about an permission role for user."
            }
            link={`/permissions`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Mail size={iconSize} />}
            title={t("emails")}
            desc={
              "You can post news To publicize to customers or business partners about this channel."
            }
            link={`/emails`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Globe size={iconSize} />}
            title={t("news")}
            desc={
              "You can post news To publicize to customers or business partners about this channel."
            }
            link={`/news`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Cpu size={iconSize} />}
            title={t("devices")}
            desc={
              "If you want to manage information about an organization related to your partners or customers."
            }
            link={`/devices`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<BarChart size={iconSize} />}
            title={t("plants")}
            desc={
              "If you want to manage information about an organization related to your partners or customers."
            }
            link={`/plants`}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <MenuConfigs
            icon={<Award size={iconSize} />}
            title={t("finances")}
            desc={
              "If you want to manage information about an organization related to your partners or customers."
            }
            link={`/finances`}
          />
        </Col>
      </Row>
    </section>
  );
};

export default memo(Dashboards);
