import api from "../services/api";

export const ORG_URL = "/organizations";

export const getOrganizations = async (filter) => {
  return api
    .get(ORG_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const postStoreOrganize = async (datas) => {
  return api.post(ORG_URL, datas).then((res) => res.data);
};

export const getEditOrganize = async (slug) => {
  return api.get(`${ORG_URL}/${slug}`).then((res) => res.data);
};

export const putOrganize = async (data, slug) => {
  return api.put(`${ORG_URL}/${slug}`, data).then((res) => res.data);
};

export const getOrgWithPlantId = async (plantId) => {
  return api.get(`${ORG_URL}/orginfo/${plantId}`).then((res) => res.data);
};
