import { memo, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { File, Download } from "react-feather";
import { Link } from "react-router-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { cn2t } from "utils";
import { getOrgWithPlantId } from "services";
import moment from "moment";

import Quotation from "components/Documents/Quotations";
import Invoices from "components/Documents/Invoices";
import Recives from "components/Documents/Recives";

const DocumentForm = ({ data }) => {
  const [stateDoc, setStateDoc] = useState(undefined);
  const [dataReport, setDataReport] = useState({});
  const curYear = moment(new Date()).format("YY");
  const curMonth = moment(new Date()).format("MM");

  const onPreviewDoc = async (value) => {
    const { data: organization } = await getOrgWithPlantId(value?.plantId);

    const totals = ((value?.price * 7) / 100 + value?.price).toFixed(2);

    const convN2Str = String(totals).split(".");

    let bahtThai = "";
    let stangThai = "";

    if (convN2Str.length > 1) {
      bahtThai = cn2t.convert(parseInt(convN2Str[0])) + " baht and ";
      stangThai = cn2t.convert(parseInt(convN2Str[1])) + " stang";
    } else {
      bahtThai = cn2t.convert(parseInt(convN2Str[0])) + " baht only";
    }

    const datas = {
      no: 1,
      topic: `Detection and analyze low preformance string ${value?.projectName}`,
      unit: 1,
      price: value?.price,
      runNo: String(value?.id).padStart(4, "0"),
      priceText: (bahtThai + " " + stangThai).toUpperCase(),
      ...organization,
      documentData: data
    };

    setDataReport(datas);
  };

  const handleStateDoc = (doc, pvFile) => {
    if (stateDoc === pvFile) {
      setStateDoc(undefined);
    } else {
      setStateDoc(undefined);
      setStateDoc(pvFile);
    }
  };

  useEffect(() => {
    onPreviewDoc(data);
  }, [data]);


  return (
    <Row>
      <Col sm={12} className="bor-sol-buttom mb-3">
        <h1 className="title-modal">Documents</h1>
      </Col>
      {(data?.documentType === "QUA" ||
        data?.documentType === "WQT" ||
        data?.documentType === "WPO" ||
        data?.documentType === "INV" ||
        data?.documentType === "WSL") && (
        <Row>
          <Col sm={8} className="mb-3">
            <label className="desc-body"> 1. File : Quotaion </label>
          </Col>
          <Col xs={4} className="mb-3 text-end">
            <Link
              to="#"
              className=""
              onClick={() => handleStateDoc(data, "QUA")}
            >
              Click Preview <File size={14} />
            </Link>{" "}
          </Col>
          {stateDoc === "QUA" && (
            <Col xs={12}>
              <div className="mb-3 text-end">
                <PDFDownloadLink
                  document={<Quotation datas={dataReport} />}
                  fileName={`QT${curYear}${curMonth}${String(data?.id).padStart(
                    4,
                    "0"
                  )}.pdf`}
                  className="text-warning"
                >
                  Download <Download size={14} />
                </PDFDownloadLink>
              </div>

              <PDFViewer style={{ width: "100%", height: 600 }}>
                <Quotation datas={dataReport} />
              </PDFViewer>
            </Col>
          )}
        </Row>
      )}
      {(data?.documentType === "WPO" ||
        data?.documentType === "INV" ||
        data?.documentType === "WSL") && (
        <Row>
          <Col sm={8} className="mb-3">
            <label className="desc-body"> 2. File : Invoice / Bill slip </label>
          </Col>
          <Col xs={4} className="mb-3 text-end">
            <Link
              to="#"
              className=""
              onClick={() => handleStateDoc(data, "INV")}
            >
              Click Preview <File size={14} />
            </Link>{" "}
          </Col>
          {stateDoc === "INV" && (
            <Col xs={12}>
              <div className="mb-3 text-end">
                <PDFDownloadLink
                  document={<Invoices datas={dataReport} />}
                  fileName={`IV${curYear}${curMonth}${String(data?.id).padStart(
                    4,
                    "0"
                  )}.pdf`}
                  className="text-warning"
                >
                  Download <Download size={14} />
                </PDFDownloadLink>
              </div>
              <PDFViewer style={{ width: "100%", height: 600 }}>
                <Invoices datas={dataReport} />
              </PDFViewer>
            </Col>
          )}
        </Row>
      )}
      {data?.documentType === "WSL" && (
        <Row>
          <Col sm={8} className="mb-3">
            <label className="desc-body">
              {" "}
              3. File : Receipt / Tax Invoice{" "}
            </label>
          </Col>
          <Col xs={4} className="mb-3 text-end">
            <Link
              to="#"
              className=""
              onClick={() => handleStateDoc(data, "RES")}
            >
              Click Preview <File size={14} />
            </Link>{" "}
          </Col>
          {stateDoc === "RES" && (
            <Col xs={12}>
              <div className="mb-3 text-end">
                <PDFDownloadLink
                  document={<Recives datas={dataReport} />}
                  fileName={`RC${curYear}${curMonth}${String(data?.id).padStart(
                    4,
                    "0"
                  )}.pdf`}
                  className="text-warning"
                >
                  Download <Download size={14} />
                </PDFDownloadLink>
              </div>
              <PDFViewer style={{ width: "100%", height: 600 }}>
                <Recives datas={dataReport} />
              </PDFViewer>
            </Col>
          )}
        </Row>
      )}

      <Col sm={12} className="mt-3">
        <h3 className="title-modal">Customer Upload</h3>
      </Col>
    </Row>
  );
};

export default memo(DocumentForm);
