import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination, Modal } from "react-bootstrap";
import Breadcrumbs from "components/Breadcrumbs";
import { useMount } from "hooks";
import { Edit, Key, Trash, Home } from "react-feather";
import { getAccounts } from "services";
import { base64 } from "utils";

// :: Import custom component :: //
import DotStatus from "components/DotStatus";
import FormChangePassword from "./FormChangePassword";
import FormSetPlant from "./FormSetPlant";

const AccountManagement = () => {
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "accounts",
      path: "/accounts",
      active: true,
    },
  ];

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [accounts, setAccounts] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  const [modalCpwd, setModalCpwd] = useState({
    isShow: false,
    data: undefined,
  });

  const [modalPlant, setModalPlant] = useState({
    isShow: false,
    data: undefined,
  });

  let filter = { page: accounts?.active || 1, limit: 10 };

  // Pagination Functions //
  const handleChangePage = (number) => {
    if (number <= 3) {
      setAccounts({
        ...accounts,
        number: 1,
        active: number,
      });
    } else if (number === accounts?.pagination?.lastPage - 1) {
      setAccounts({
        ...accounts,
        number: number - 3,
        active: number,
      });
    } else if (number === accounts?.pagination?.lastPage) {
      setAccounts({
        ...accounts,
        number: number - 4,
        active: number,
      });
    } else {
      setAccounts({
        ...accounts,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (accounts?.active >= accounts?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(accounts?.active + 1);
  };

  const handleChangePrev = () => {
    if (accounts?.active > 0) {
      if (accounts?.active === 1) {
        handleChangePage(accounts?.active);
      } else {
        handleChangePage(accounts?.active - 1);
      }
    }
  };

  const handleConvertStatus = (str) => {
    if (str === "ACT") {
      return "Active";
    } else if (str === "PDI") {
      return "Pendding";
    } else {
      return "Inactive";
    }
  };

  const handleConvertColorStatus = (str) => {
    if (str === "ACT") {
      return "green";
    } else if (str === "PDI") {
      return "yellow";
    } else {
      return "red";
    }
  };

  const handleLastPage = () => {
    handleChangePage(accounts?.pagination?.lastPage);
  };

  const hdClosed = () => {
    setModalCpwd({
      isShow: false,
      data: undefined,
    });

    setModalPlant({
      isShow: false,
      data: undefined,
    });
  };
  // End Pagination Function //

  const items = [];

  for (
    let number = accounts?.number;
    number <=
    (accounts?.pagination?.lastPage < 5
      ? accounts?.pagination?.lastPage
      : accounts?.number + 4);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === accounts?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getAccounts(filter);

      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      setAccounts({
        ...accounts,
        data: results?.accounts,
        pagination: {
          ...accounts.paginate,
          current,
          pageSize,
          total,
          lastPage,
        },
      });
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    fetchDataDefault();
  }, [accounts?.active]);

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Account Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end">
          <Button variant="outline-primary" size="sm" href="/accounts/create">
            Create Account
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Telephone</th>
                <th>User Type</th>
                <th>Status</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {accounts?.data?.map((acc, key) => {
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td>{acc?.username}</td>
                    <td>{`${acc?.firstName} ${acc?.middleName ?? ""} ${
                      acc?.lastName
                    }`}</td>
                    <td>{acc?.email}</td>
                    <td>{acc?.phoneNumber}</td>
                    <td>{acc?.userType}</td>
                    <td>
                      <DotStatus
                        value={handleConvertStatus(acc?.status)}
                        color={handleConvertColorStatus(acc?.status)}
                      />
                    </td>
                    <td className="text-center">
                      <Link
                        to={`#`}
                        className="me-3"
                        onClick={() =>
                          setModalPlant({
                            isShow: true,
                            data: acc,
                          })
                        }
                      >
                        <Home size={16} className="text-primary" />
                      </Link>
                      <Link
                        to={`#`}
                        className="me-3"
                        onClick={() =>
                          setModalCpwd({
                            isShow: true,
                            data: acc,
                          })
                        }
                      >
                        <Key size={16} className="text-warning" />
                      </Link>
                      <Link
                        to={`/accounts/${base64.utf8ToB64(acc?.username)}`}
                        className="me-3"
                      >
                        <Edit size={16} className="text-info" />
                      </Link>
                      <Link to={`#`}>
                        <Trash size={16} className="text-danger" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {/*  */}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination size="sm" className="justify-content-end">
                <Pagination.First onClick={() => handleChangePage(1)} />
                <Pagination.Prev onClick={() => handleChangePrev()} />
                {items}
                <Pagination.Next onClick={() => handleChangeNext()} />
                <Pagination.Last onClick={() => handleLastPage()} />
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal show={modalCpwd?.isShow}>
        <Modal.Body className="dark-theme">
          <FormChangePassword closed={hdClosed} data={modalCpwd?.data} />
        </Modal.Body>
      </Modal>

      <Modal show={modalPlant?.isShow}>
        <Modal.Body className="dark-theme">
          <FormSetPlant closed={hdClosed} data={modalPlant?.data} />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default memo(AccountManagement);
