import { memo, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { Key } from "react-feather";
import { putChangePassword } from "services";

const FormChangePassword = ({ closed, data }) => {
  const [validated, setValidated] = useState(false);
  const [messageErr, setMessageErr] = useState(false);

  // onsole.log(data);
  const handleSubmit = async (event) => {
    setMessageErr(false);
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    const checkCFpwd = validatePasswordEquals(
      formDataObj?.password,
      formDataObj?.confirmPassword
    );

    if (checkCFpwd) {
      const { success } = await putChangePassword(data?.username, {
        password: formDataObj?.password,
        cfPassword: formDataObj?.confirmPassword,
      });

      if (success) {
        closed();
      }
    } else {
      setMessageErr(true);
    }

    // const username = data?.username;
  };

  const validatePasswordEquals = (pwd, cpwd) => {
    if (pwd === cpwd) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Row>
        <Col sm={12} className="bor-sol-buttom">
          <h1 className="title-modal">Change Password</h1>
        </Col>

        <Col sm={12}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} className="my-3">
                <p className="text-topic">Password</p>
                <InputGroup>
                  <InputGroup.Text>
                    <Key size={16} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Password"
                    aria-label="Password"
                    type="password"
                    name="password"
                    autoComplete="false"
                    required
                  />
                </InputGroup>
              </Col>
              <Col sm={12} className="me-3 mb-3">
                <p className="text-topic">Confirm Password</p>
                <InputGroup>
                  <InputGroup.Text>
                    <Key size={16} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Password"
                    aria-label="Password"
                    type="password"
                    name="confirmPassword"
                    autoComplete="false"
                    required
                  />
                </InputGroup>
              </Col>
              <Col sm={12} className="mb-3 text-end">
                <Button
                  variant="outline-primary"
                  className="me-3"
                  type="submit"
                >
                  Change Password
                </Button>
                <Button variant="outline-danger" onClick={() => closed()}>
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default memo(FormChangePassword);
