import axios from "axios";

const tokenOpenweather = process.env.REACT_APP_OPENWEATHER_KEY ?? "";
const OPEN_FORECAST_URL = "https://api.openweathermap.org/data/2.5/forecast?";
const OPEN_WEATHER_URL = "https://api.openweathermap.org/data/2.5/weather?";

// :: External Api with third partys :: //
export const getOpenWeatherForecasts = async (lat, lon) => {
  return axios
    .get(`${OPEN_FORECAST_URL}lat=${lat}&lon=${lon}&appid=${tokenOpenweather}`)
    .then((res) => res.data);
};

export const getWeatherCurrent = async (lat, lon) => {
  return axios
    .get(`${OPEN_WEATHER_URL}lat=${lat}&lon=${lon}&appid=${tokenOpenweather}`)
    .then((res) => res.data);
};
