import api from "./api";

export const EMAIL_SENDER_PATH = "/emailers";

export const getEmails = async (filter) => {
  return api
    .get(EMAIL_SENDER_PATH, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createEmail = async (data) => {
  return api.post(`${EMAIL_SENDER_PATH}`, data).then((res) => res.data);
};

export const desTroyEmails = async (emailId) => {
  return api.get(`${EMAIL_SENDER_PATH}/${emailId}`).then((res) => res.data);
};
