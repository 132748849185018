import { memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs";
import FormMaintain from "./FormMaintain";
import { postStoreProject, getEditPlant } from "services";

import { useEffect } from "react";

const MaintainPlants = () => {
  const navigate = useNavigate();
  const { slug, proslug } = useParams();

  const [initialData, setInitialData] = useState({});
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "plants",
      path: "/plants",
      active: false,
    },
    {
      lable: "projects",
      path: `/plant/projects/${slug}`,
      active: false,
    },
    {
      lable: `${proslug === "create" ? "create" : "edit"}`,
      active: true,
    },
  ];
  const handleSubmit = async (values) => {
    if (proslug === "create") {
      const { success } = await postStoreProject(slug, values);
      if (success) {
        navigate(`/plant/projects/${slug}`);
      }
    } else {
    }
  };

  const fetchDataDefault = async () => {
    try {
      const { data } = await getEditPlant(slug);
      setInitialData({ ...data });
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (proslug !== "create") {
      fetchDataDefault();
    }
  }, []);
  return (
    <section className="maintain">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page">Project Maintain</h1>
      <FormMaintain onFinish={handleSubmit} initial={initialData} />
    </section>
  );
};

export default memo(MaintainPlants);
