import { memo, useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { Save } from "react-feather";

const FormMaintain = ({ onFinish, initialData }) => {
  console.log(initialData);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      onFinish({ ...formDataObj });
    }

    setValidated(true);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">menu name</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Menu Name"
                    aria-label="Menu Name"
                    name="menuName"
                    defaultValue={initialData?.menuName}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">Path</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Path"
                    aria-label="Path"
                    name="paths"
                    defaultValue={initialData?.paths}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">description</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Description"
                    aria-label="Description"
                    as="textarea"
                    name="description"
                    defaultValue={initialData?.description}
                    rows={4}
                    required
                  />
                </InputGroup>
              </Col>

              <Col sm={12} className="text-end">
                <Button variant="outline-primary" type="submit">
                  <Save size={18} className="me-2" />
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(FormMaintain);
