import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "../i18n";

// Icon Flags
import English from "assets/images/flags/en.png";
import Thailand from "assets/images/flags/th.png";
import Chinese from "assets/images/flags/ch.png";

const SelectorLanguages = ({ t }) => {
  console.log(i18n?.language);
  const handleChangeLanguages = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <p className="text-white mb-1">{t("change_language")}</p>
      <Dropdown className="mb-3">
        <Dropdown.Toggle className="w-100" variant="outline-primary">
          {i18n?.language === "en" && (
            <Image src={English} width={24} className="me-3" />
          )}

          {i18n?.language === "th" && (
            <Image src={Thailand} width={24} className="me-3" />
          )}

          {i18n?.language === "ch" && (
            <Image src={Chinese} width={24} className="me-3" />
          )}

          <span className="mt-1">{t(i18n?.language)}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={() => handleChangeLanguages("en")}>
            <Image src={English} width={24} className="me-3" />
            English
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleChangeLanguages("th")}>
            <Image src={Thailand} width={24} className="me-3" />
            ไทย
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleChangeLanguages("ch")}>
            <Image src={Chinese} width={24} className="me-3" />
            中国
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SelectorLanguages;
