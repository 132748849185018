import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination } from "react-bootstrap";
import { useMount } from "hooks";
import { Edit, Trash, Eye, List } from "react-feather";
import moment from "moment";
import { base64 } from "utils";

import { getPlants } from "services";

// :: Component Customs :: //
import Breadcrumbs from "components/Breadcrumbs";
import DotStatus from "components/DotStatus";

const Plants = () => {
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "plants",
      active: true,
    },
  ];

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [plants, setPlants] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  // Pagination Functions //
  const handleChangePage = (number) => {
    if (number <= 3) {
      setPlants({
        ...plants,
        number: 1,
        active: number,
      });
    } else if (number === plants?.pagination?.lastPage - 1) {
      setPlants({
        ...plants,
        number: number - 3,
        active: number,
      });
    } else if (number === plants?.pagination?.lastPage) {
      setPlants({
        ...plants,
        number: number - 4,
        active: number,
      });
    } else {
      setPlants({
        ...plants,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (plants?.active >= plants?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(plants?.active + 1);
  };

  const handleChangePrev = () => {
    if (plants?.active > 0) {
      if (plants?.active === 1) {
        handleChangePage(plants?.active);
      } else {
        handleChangePage(plants?.active - 1);
      }
    }
  };

  const handleLastPage = () => {
    handleChangePage(plants?.pagination?.lastPage);
  };
  // End Pagination Function //

  const items = [];

  for (
    let number = plants?.number;
    number <=
    (plants?.pagination?.lastPage < 5
      ? plants?.pagination?.lastPage
      : plants?.number + 4);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === plants?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getPlants();

      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      console.log(results?.plants);

      setPlants({
        ...plants,
        data: results?.plants,
        pagination: {
          ...plants.paginate,
          current,
          pageSize,
          total,
          lastPage,
        },
      });
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [plants?.pagination?.lastPage]);

  // convert Status Value
  const handleConvertStatus = (str) => {
    if (str === "ACT") {
      return "Active";
    } else if (str === "PDI") {
      return "Pendding";
    } else {
      return "Inactive";
    }
  };

  const handleConvertColorStatus = (str) => {
    if (str === "ACT") {
      return "green";
    } else if (str === "PDI") {
      return "yellow";
    } else {
      return "red";
    }
  };

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Plant Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end">
          <Button variant="outline-primary" size="sm" href="/plants/create">
            Create Plant
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th className="text-center">Previews</th>
                <th>Plant Name</th>
                <th>Power Size</th>
                <th>Unit</th>
                <th>Status</th>
                <th>Updated At</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {plants?.data?.map((pl, key) => {
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td className="text-center">
                      <Link to="#">
                        <Eye size={16} />
                      </Link>
                    </td>
                    <td>{pl?.plantName}</td>
                    <td>{pl?.powerSize}</td>
                    <td>{pl?.unit}</td>
                    <td>
                      <DotStatus
                        value={handleConvertStatus(pl?.status)}
                        color={handleConvertColorStatus(pl?.status)}
                      />
                    </td>
                    <td>
                      {moment(pl.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-center">
                      <Link to={`/plant/projects/${pl?.slug}`} className="me-3">
                        <List size={16} className="text-primary" />
                      </Link>
                      <Link to={`/plants/${pl.slug}`} className="me-3">
                        <Edit size={16} className="text-info" />
                      </Link>
                      <Link to={`#`}>
                        <Trash size={16} className="text-danger" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {/*  */}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination size="sm" className="justify-content-end">
                <Pagination.First onClick={() => handleChangePage(1)} />
                <Pagination.Prev onClick={() => handleChangePrev()} />
                {items}
                <Pagination.Next onClick={() => handleChangeNext()} />
                <Pagination.Last onClick={() => handleLastPage()} />
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default memo(Plants);
