import { memo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs";
import FormMaintain from "./FormMaintain";

import { postStoreMenu, getEditMenu, postUpdateMenu } from "services";

const MaintainPlants = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  let eventTypes = slug === "create" ? "create" : "edit";
  const [menu, setMenu] = useState({});
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "menus",
      path: "/menus",
      active: false,
    },
    {
      lable: `${eventTypes}`,
      active: true,
    },
  ];
  const handleSubmit = async (values) => {
    if (eventTypes === "create") {
      const { success } = await postStoreMenu(values);
      navigate("/menus");
    } else {
      const { success } = await postUpdateMenu(slug, values);
      navigate("/menus");
    }
  };

  const fetchDataEdit = async () => {
    const { data } = await getEditMenu(slug);
    setMenu(data?.menu);
  };

  useEffect(() => {
    if (eventTypes !== "create") {
      fetchDataEdit();
    }
  }, []);

  return (
    <section className="maintain">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page">Menu Maintain</h1>
      <FormMaintain onFinish={handleSubmit} initialData={menu} />
    </section>
  );
};

export default memo(MaintainPlants);
