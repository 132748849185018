import { memo, useEffect, useState } from "react";

import Breadcrumbs from "components/Breadcrumbs";
import FormMaintain from "./FormMaintain";

import { postStoreOrganize } from "services";
import { useNavigate, useParams } from "react-router";
import { getEditOrganize } from "services/OrganizeService";
import { putOrganize } from "../../../services/OrganizeService";

const MaintainOrganizes = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState([]);
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "organizations",
      path: "/organizations",
      active: false,
    },
    {
      lable: `${slug === "create" ? "create" : "edit"}`,
      active: true,
    },
  ];
  const handleSubmit = async (values) => {
    if (slug === "create") {
      const { success } = await postStoreOrganize(values);
      if (success) {
        navigate("/organizations");
      }
    } else {
      const { success } = await putOrganize(values, slug);
      if (success) {
        navigate("/organizations");
      }
    }
  };

  const fetchDataDefault = async () => {
    try {
      const { data } = await getEditOrganize(slug);
      setInitialData({ ...data });
    } catch {
      navigate("/organizations");
    }
  };
  console.log(initialData);

  useEffect(() => {
    fetchDataDefault();
  }, []);

  return (
    <section className="maintain">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page">Organization Maintain</h1>
      <FormMaintain onFinish={handleSubmit} initial={initialData} />
    </section>
  );
};

export default memo(MaintainOrganizes);
