import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import commaNumber from "comma-number";
import LogoDoc from "assets/images/logos/Five.jpg";
import RussarinSign from "assets/images/signatures/Russarin.png";
import NattaponSign from "assets/images/signatures/NattaponSamakrut.png";

import { getUserInfo } from "services";

Font.register({
  family: "Kanit",
  fonts: [],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 11,
    textAlign: "left",
    marginTop: 20,
  },
  headTitle: {
    fontSize: 10,
    textAlign: "left",
  },
  subtitle: {
    marginTop: 5,
    fontSize: 9,
  },
  subtitleCenter: {
    marginTop: 15,
    fontSize: 10,
    textAlign: "center",
  },
  subtitleCenterBig: {
    marginBottom: 30,
    margintop: 15,
    fontSize: 10,
    textAlign: "center",
  },
  subtitleRight: {
    marginTop: 5,
    textAlign: "right",
    fontSize: 10,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  text: {
    margin: 12,
    fontSize: 14,
  },
  headerLeft: {
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 1,
    textAlign: "left",
    color: "#000",
  },
  headerSubLeft: {
    fontSize: 7,
    fontWeight: "normal",
    marginBottom: 1,
    textAlign: "left",
    color: "#000",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  row: {
    flexDirection: "row",
    marginTop: 5,
  },
  row2: {
    flexDirection: "row",
  },
  row3: {
    marginTop: 40,
    flexDirection: "row",
  },
  page10: {
    width: "10%",
  },
  page15: {
    width: "15%",
  },
  page17: {
    width: "17%",
  },
  page18: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    paddingRight: 5,
  },
  page20: {
    width: "20%",
  },
  page30: {
    width: "30%",
  },
  page40: {
    width: "40%",
  },
  page50: {
    width: "50%",
  },
  page60: {
    width: "60%",
  },
  page65: {
    width: "65%",
  },
  page100: {
    width: "100%",
    marginTop: 10,
  },
  border100: {
    width: "100%",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 5,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColNo: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  subTableColNo: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    height: 120,
  },
  tableColDesc: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableColDesc: {
    width: "41%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHead: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    fontStyle: 12,
    fontWeight: "bold",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableCellLeft: {
    marginTop: 5,
    fontSize: 10,
    textAlign: "left",
  },
  tableCellRight: {
    marginTop: 5,
    fontSize: 10,
    textAlign: "right",
  },
  image: {
    width: 120,
    marginBottom: 10,
  },
  signature: {
    width: "80%",
    height: 70,
    justifyContent: "center",
    alignItems: "center",
  },
  dotSignature: {
    fontSize: 10,
    textAlign: "center",
    marginTop: -15,
  },
});

// Create Document Component
const Quotations = ({ datas }) => {
  const nowDate = new Date();
  const dateAddThirty = new Date();
  dateAddThirty.setDate(dateAddThirty.getDate() + 15);
  const convertDate = moment(nowDate).format("DD/MM/YYYY");
  let totals = 0;
  const userInfo = getUserInfo();

  console.log(userInfo);

  totals = datas?.price;

  const fixedNum = 0;
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <View style={styles.row}>
          <View style={styles.page50}>
            <Image style={styles.image} src={LogoDoc} />
            <Text style={styles.headerLeft} fixed>
              EQUATOR SOLAR FIVE CO.,LTD.
            </Text>
            <Text style={styles.headerSubLeft} fixed>
              1339 Arwan Build Floor 8, Pracharad 1 Rd, Wongsawang, Bangsue,
              Bangkok 10800
            </Text>
            <Text style={styles.headerSubLeft} fixed>
              TEL. (66)2150-5731 FAX. (66)2150-5732 E-mail : info@enserv.co.th
            </Text>
            <Text style={styles.headerSubLeft} fixed>
              Tax ID : 0135555008715
            </Text>
          </View>
          <View style={styles.page30}></View>
          <View style={styles.page20}>
            <Text style={styles.headTitle} fixed>
              Quotation
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.page50}>
            <Text style={styles.title}>Customer</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.page50}>
            <Text style={styles.subtitle}>{datas?.oraganization?.orgName}</Text>
            <Text style={styles.subtitle}>{datas?.oraganization?.address}</Text>

            <Text style={styles.subtitle}>
              Tel : {datas?.oraganization?.phoneNumber} Fax :{" "}
              {datas?.oraganization?.fax}
            </Text>
            <Text style={styles.subtitle}>
              Contact : {datas?.oraganization?.contactNumber} Tax ID :{" "}
              {datas?.oraganization?.taxCode}
            </Text>
          </View>
          <View style={styles.page10}></View>
          <View style={styles.page40}>
            <Text style={styles.subtitle}>
              Quotation No. : QT5{moment().format("YY")}
              {moment().format("MM")}
              {datas?.runNo}
            </Text>
            <Text style={styles.subtitle}>
              Date : {moment().format("DD/MM/YYYY")} Expire : {` `}
              {moment(dateAddThirty).format("DD/MM/YYYY")}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.page100}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColNo}>
                  <Text style={styles.tableCellHead}>No.</Text>
                </View>
                <View style={styles.tableColDesc}>
                  <Text style={styles.tableCellHead}>Item / Description</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHead}>Quantity / Unit</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHead}>Unit Price</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHead}>Amount</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.subTableColNo}>
                  <Text style={styles.tableCell}>{datas?.no}</Text>
                </View>
                <View style={styles.tableColDesc}>
                  <Text style={styles.tableCellLeft}>{datas?.topic}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellRight}>
                    {datas?.unit ?? (datas?.unit).toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellRight}>
                    {commaNumber(datas?.price.toFixed(2))}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellRight}>
                    {commaNumber(datas?.price.toFixed(2) * datas?.unit)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.row2}>
          <View style={styles.page65}>
            <Text style={styles.subtitle}>Remark</Text>
            <Text style={styles.subtitle}>
              {`=> If you require any futher information, please contact ${
                userInfo?.firstName ?? ""
              } ${userInfo?.middleName ?? ""} ${userInfo?.lastName ?? ""}`}
            </Text>
            <Text style={styles.subtitle}>
              {`  Tel.: ${userInfo?.phoneNumber}, Email: ${userInfo?.email}`}
            </Text>
            <Text style={styles.subtitle}>
              {`=> Please refer quatation No. with purchasing order.`}
            </Text>
            <Text style={styles.subtitle}>{`=> Credit term 30 days`}</Text>

            <Text style={styles.subtitle}>
              ..................................................................................................................
            </Text>
          </View>
          <View style={styles.page17}>
            <Text style={styles.subtitle}>Subtotal : </Text>
            <Text style={styles.subtitle}>Less Discount : </Text>
            <Text style={styles.subtitle}>After Discount : </Text>
            <Text style={styles.subtitle}>VAT Amount : 7% </Text>
            <Text style={styles.subtitle}>Total Invoice : </Text>
          </View>
          <View style={styles.page18}>
            <Text style={styles.subtitleRight}>
              {commaNumber(totals.toFixed(2))}
            </Text>
            <Text style={styles.subtitleRight}>
              {commaNumber(fixedNum.toFixed(2))}
            </Text>
            <Text style={styles.subtitleRight}>
              {commaNumber(totals.toFixed(2))}
            </Text>
            <Text style={styles.subtitleRight}>
              {commaNumber(((totals * 7) / 100).toFixed(2))}
            </Text>
            <Text style={styles.subtitleRight}>
              {commaNumber(((totals * 7) / 100 + totals).toFixed(2))}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.page100}>
            <Text style={styles.subtitle}>( {datas?.priceText} )</Text>
            <Text style={styles.border100}></Text>
          </View>
        </View>

        <View style={styles.row3}>
          <View style={styles.page10}></View>
          <View style={styles.page30}>
            <Image
              style={
                (styles.signature,
                {
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "80%",
                  height: 70,
                })
              }
              src={NattaponSign}
            />
            <Text style={styles.dotSignature}>
              ........................................................
            </Text>
            <Text style={styles.subtitleCenter}>
              Nattapon Samakrut
              {/* ({" "}
              {`${userInfo?.firstName ?? ""} ${userInfo?.middleName ?? ""} ${
                userInfo?.lastName ?? ""
              }`}{" "}
              ) */}
            </Text>
            <Text style={styles.subtitleCenter}>Senior Sales Engineer</Text>
            <Text style={styles.subtitleCenter}>{convertDate}</Text>
          </View>
          <View style={styles.page20}></View>
          <View style={styles.page30}>
            <Image
              style={
                (styles.signature,
                {
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "80%",
                  height: 70,
                })
              }
              src={RussarinSign}
            />
            <Text style={styles.dotSignature}>
              ........................................................
            </Text>
            <Text style={styles.subtitleCenter}>
              ( Russarin Ngamnitiwarawat )
            </Text>
            <Text style={styles.subtitleCenter}>Cheif Operation Officer</Text>
            <Text style={styles.subtitleCenter}>{convertDate}</Text>
          </View>
          <View style={styles.page10}></View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Quotations;
