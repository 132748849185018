import api from "./api";

export const PERMISSION_URL = "/permissions";

export const getPermissions = async (filter) => {
  return api
    .get(PERMISSION_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const postStorePermission = async (datas) => {
  return api.post(PERMISSION_URL, datas).then((res) => res.data);
};

export const getEditPermission = async (slug) => {
  return api.get(`${PERMISSION_URL}/${slug}`).then((res) => res.data);
};

export const putUpdatePermission = async (slug, data) => {
  return api.put(`${PERMISSION_URL}/${slug}`, data).then((res) => res.data);
};
