import api from "../services/api";
import { base64 } from "utils";

// TODO: Find where to keep these constants
export const LOGIN_URL = "/authens/signin";
export const LOGOUT_URL = "/authens/signout";
export const REFRESH_URL = "/authens/refresh";
export const USER_INFO_URL = "/authens/info";

// TODO: Find where to keep these constants
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const USER_INFO_KEY = "userInfo";
export const USER_PERMISSION_KEY = "userPermission";

export const getAccessToken = () => {
  if (typeof window === "undefined") return "";

  const jsonString = localStorage.getItem(ACCESS_TOKEN_KEY);

  return jsonString ? JSON.parse(jsonString) : undefined;
};

export const getRefreshToken = () => {
  if (typeof window === "undefined") return "";

  const jsonString = localStorage.getItem(REFRESH_TOKEN_KEY);

  return jsonString ? JSON.parse(jsonString) : undefined;
};

export const setToken = (accessToken = "") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(accessToken));
};

export const setRefreshToken = (refreshToken = "") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refreshToken));
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const setUserInfo = (userDetail) => {
  if (typeof window === "undefined") return;
  localStorage.setItem(
    USER_INFO_KEY,
    base64.utf8ToB64(JSON.stringify(userDetail))
  );
};

export const clearUserInfo = () => {
  localStorage.removeItem(USER_INFO_KEY);
};

export const getUserInfo = () => {
  if (typeof window === "undefined") return "";

  const jsonString = localStorage.getItem(USER_INFO_KEY);

  return jsonString ? JSON.parse(base64.b64ToUtf8(jsonString)) : undefined;
};

export async function destroyUserSession() {
  clearUserInfo();
  clearTokens();
  window.location.assign("/login");
}

export async function getAuthInfo() {
  return api.get(USER_INFO_URL).then(async (res) => {
    clearUserInfo();
    setUserInfo(res?.data);
  });
}

export async function login(username, password) {
  return api.post(LOGIN_URL, { username, password }).then(async (res) => {
    clearTokens();
    setToken(res?.data?.token);
    setRefreshToken(res?.data?.refreshToken);
    await getAuthInfo();
    window.location.assign("/");
  });
}

export async function logout() {
  const token = getAccessToken();

  if (token === "") throw new Error("No token");

  const res = await api.post(LOGOUT_URL, { token });

  destroyUserSession();
  return res.data;
}

export async function refresh() {
  const refreshToken = getRefreshToken();
  if (refreshToken === "") throw new Error("No refresh token");

  const res = await api.post(REFRESH_URL, { refreshToken });

  setToken(res?.data?.accessToken);
  setRefreshToken(res?.data?.refreshToken);
}
