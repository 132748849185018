import { memo } from "react";

const DotStatus = ({ value, color }) => {
  return (
    <div>
      <label className={`dot me-3 ${color}`} />
      <label>{value}</label>
    </div>
  );
};

export default memo(DotStatus);
