import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Modal } from "react-bootstrap";
import { useMount } from "hooks";
import { CheckCircle, Paperclip, Upload } from "react-feather";
import Swal from "sweetalert2";
import moment from "moment";
import { getFinances, approveSlips } from "services";

import Breadcrumbs from "components/Breadcrumbs";
import DocumentForm from "./DocumentForm";
import UploadFileForm from "./UploadFileForm";

const FinanceManagemenet = () => {
  const [document, setDocument] = useState({
    show: false,
    data: {},
  });

  const [uploaddoc, setUploadDoc] = useState({
    show: false,
    data: {},
  });

  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "finances",
      active: true,
    },
  ];

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [finances, setFinances] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getFinances();
      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      setFinances({ ...finances, data: results?.finances });
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [finances]);

  /** Document */
  const handleModalDocument = (props) => {
    setDocument({
      show: true,
      data: props,
    });
  };

  const handleCloseDocument = () => {
    setDocument({
      show: false,
      data: {},
    });
  };

  /** Upload Documents */
  const handleModalUploadDocs = (props) => {
    setUploadDoc({
      show: true,
      data: props,
    });
  };

  const handleCloseUploadDocs = () => {
    setUploadDoc({
      show: false,
      data: {},
    });
    fetchDataDefault();
  };

  const handleApprovedSlips = async (value) => {
    Swal.fire({
      title: "Are you sure approve this billing?",
      text: "if you approve this please click confirm",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2b766c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm, approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { success } = await approveSlips(value);
        if (success) {
          Swal.fire("Approve!", "This billing is approved.", "success");
          fetchDataDefault();
        } else {
          Swal.fire("Approve!", "Someting wrong !", "warning");
          fetchDataDefault();
        }
      }
    });
  };

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Finance Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end"></Col>
      </Row>
      <Row>
        <Col lg={12} sm={12}>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Plant Name</th>
                <th>Project Name</th>
                <th>Payment Type</th>
                <th>Document Type</th>
                <th>Price</th>
                <th>Status</th>
                <th>Updated</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {finances?.data?.map((res, key) => {
                console.log(res);
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td>{res?.plantName}</td>
                    <td>{res?.projectName}</td>
                    <td>{res?.typePayment}</td>
                    <td>{res?.documentType}</td>
                    <td>{res?.price}</td>
                    <td>{res?.status}</td>
                    <td>
                      {moment(res?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td className="text-center">
                      {!res?.approved && res?.documentType === "WSL" && (
                        <Link
                          to={`#`}
                          className="me-3"
                          onClick={() => handleApprovedSlips(res)}
                        >
                          <CheckCircle size={16} className="text-success" />
                        </Link>
                      )}

                      <Link
                        to={`#`}
                        className="me-3"
                        onClick={() => handleModalDocument(res)}
                      >
                        <Paperclip size={16} className="text-primary" />
                      </Link>

                      {res?.documentType !== "COM" &&
                        (res?.documentType !== "WSL" || res.approved) && (
                          <Link
                            to={`#`}
                            className="me-3"
                            onClick={() => handleModalUploadDocs(res)}
                          >
                            <Upload size={16} className="text-warning" />
                          </Link>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={document?.show} size="xl" onHide={handleCloseDocument}>
        <Modal.Body className="dark-theme">
          {document?.show && <DocumentForm data={document?.data} />}
        </Modal.Body>
      </Modal>

      <Modal show={uploaddoc?.show} size="xl" onHide={handleCloseUploadDocs}>
        <Modal.Body className="dark-theme">
          <UploadFileForm
            data={uploaddoc?.data}
            onClose={handleCloseUploadDocs}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default memo(FinanceManagemenet);
