import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination } from "react-bootstrap";
import Breadcrumbs from "components/Breadcrumbs";
import { useMount } from "hooks";
import { Edit, Key, Trash } from "react-feather";
import { getOrganizations } from "services";
import { format, parseISO } from "date-fns";

const OrganizationManagement = () => {
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "organizations",
      path: "/organizations",
      active: true,
    },
  ];

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "yyyy-MM-dd HH:mm:ss");
  };

  const initPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  };

  const [organizes, setOrganizes] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  // Pagination Functions //
  const handleChangePage = (number) => {
    if (number <= 3) {
      setOrganizes({
        ...organizes,
        number: 1,
        active: number,
      });
    } else if (number === organizes?.pagination?.lastPage - 1) {
      setOrganizes({
        ...organizes,
        number: number - 3,
        active: number,
      });
    } else if (number === organizes?.pagination?.lastPage) {
      setOrganizes({
        ...organizes,
        number: number - 4,
        active: number,
      });
    } else {
      setOrganizes({
        ...organizes,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (organizes?.active >= organizes?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(organizes?.active + 1);
  };

  const handleChangePrev = () => {
    if (organizes?.active > 0) {
      if (organizes?.active === 1) {
        handleChangePage(organizes?.active);
      } else {
        handleChangePage(organizes?.active - 1);
      }
    }
  };

  const handleLastPage = () => {
    handleChangePage(organizes?.pagination?.lastPage);
  };
  // End Pagination Function //

  const items = [];

  for (
    let number = organizes?.number;
    number <=
    (organizes?.pagination?.lastPage < 5
      ? organizes?.pagination?.lastPage
      : organizes?.number + 4);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === organizes?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getOrganizations();
      const current = results?.paginate?.current_page || 1;
      const pageSize = results?.paginate?.per_page || 10;
      const total = results?.paginate?.total || 0;
      const lastPage = results?.paginate?.last_page || 0;

      setOrganizes({
        ...organizes,
        data: results?.organizes,
        pagination: {
          ...organizes.paginate,
          current,
          pageSize,
          total,
          lastPage,
        },
      });
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [organizes?.pagination?.lastPage]);

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={8} lg={8} sm={8} xs={8}>
          <h1 className="header-page">Organizations Management</h1>
        </Col>
        <Col xl={4} lg={4} sm={4} xs={4} className="text-end">
          <Button
            variant="outline-primary"
            size="sm"
            href="/organizations/create"
          >
            Create Organize
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Telephone</th>
                <th>Updated At</th>
                <th className="text-center">Manages</th>
              </tr>
            </thead>
            <tbody>
              {organizes?.data?.map((acc, key) => {
                return (
                  <tr key={key}>
                    <td>{(key += 1)}</td>
                    <td>{acc?.orgName}</td>
                    <td>{acc?.email}</td>
                    <td>{acc?.phoneNumber}</td>
                    <td>{formatDate(acc?.createdAt)}</td>
                    <td className="text-center">
                      <Link to={`/organizations/${acc.slug}`} className="me-3">
                        <Edit size={16} className="text-info" />
                      </Link>
                      <Link to={`#`}>
                        <Trash size={16} className="text-danger" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {/*  */}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination size="sm" className="justify-content-end">
                <Pagination.First onClick={() => handleChangePage(1)} />
                <Pagination.Prev onClick={() => handleChangePrev()} />
                {items}
                <Pagination.Next onClick={() => handleChangeNext()} />
                <Pagination.Last onClick={() => handleLastPage()} />
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default memo(OrganizationManagement);
