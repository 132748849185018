import api from "../services/api";

export const ACCOUNT_URL = "/accounts";

export const getAccounts = async (filter) => {
  return api
    .get(ACCOUNT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const postStoreAccount = async (datas) => {
  return api.post(ACCOUNT_URL, datas).then((res) => res.data);
};

export const getEditAccount = async (username) => {
  return api.get(`${ACCOUNT_URL}/${username}`).then((res) => res.data);
};

export const putUpdateAccount = async (username, data) => {
  return api.put(`${ACCOUNT_URL}/${username}`, data).then((res) => res.data);
};

export const putChangePassword = async (username, data) => {
  return api
    .put(`${ACCOUNT_URL}/changePassword/${username}`, data)
    .then((res) => res.data);
};

export const setAccountToPlant = async (data) => {
  return api.post(`${ACCOUNT_URL}/setplants`, data).then((res) => res.data);
};
