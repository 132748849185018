import { memo, useState, useEffect, useRef } from "react";
import { Row, Col, InputGroup, Form, Image, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Breadcrumbs from "components/Breadcrumbs";
import { User, Key } from "react-feather";
import { userTypes } from "const";
import { useMount } from "hooks";
import { base64 } from "utils";

import { postStoreAccount, getEditAccount, putUpdateAccount } from "services";

// :: Images :: //
import BlankImage from "assets/images/defaults/blank_500x500.png";

const AccountMaintains = () => {
  const [initialData, setInitialData] = useState({});
  const [selUserType, setSelUserType] = useState(undefined);
  const [profileImage, setProfileImage] = useState("");
  const { hashUsername } = useParams();
  const hiddenFileInput = useRef(null);

  const navigate = useNavigate();
  const breadcrumbs = [
    {
      lable: "dashboards",
      path: "/",
      active: false,
    },
    {
      lable: "accounts",
      path: "/accounts",
      active: false,
    },
    {
      lable: `${hashUsername === "create" ? "create" : "edit"}`,
      active: true,
    },
  ];

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    let success = false;
    if (hashUsername === "create") {
      const data = await postStoreAccount({
        ...formDataObj,
        profileImage,
      });

      success = data?.success;
    } else {
      const data = await putUpdateAccount(base64.b64ToUtf8(hashUsername), {
        ...formDataObj,
        profileImage,
      });

      success = data?.success;
    }

    if (success) {
      navigate("/accounts");
    }

    setValidated(true);
  };

  const fetchDataDefault = async () => {
    try {
      if (hashUsername !== "create") {
        const username = base64.b64ToUtf8(hashUsername);

        const { data } = await getEditAccount(username);

        setInitialData({ ...data?.account });
        setProfileImage(data?.account?.profileImage);

        if (data) {
          userTypes.filter((item) => {
            if (item?.value === data?.account?.userType) {
              return setSelUserType(item);
            }
          });
        }
      } else {
        return false;
      }
    } catch (error) {}
  };

  const handleSelectUserType = async (value) => {
    setSelUserType(value);
  };

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });

    setProfileImage(data);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [initialData, selUserType]);

  return (
    <section className="main-contents">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col xl={12} lg={12} sm={12} xs={12}>
          <h1 className="header-page">Account Maintain</h1>
        </Col>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col xl={3} lg={3} sm={12} xs={12}>
              <Row>
                <Col sm={12}>
                  <p className="text-topic mb-1"> picture profile</p>
                  <Image
                    className="w-100 profile-round mb-3"
                    src={profileImage || BlankImage}
                  />
                  <Button
                    variant="outline-primary w-100"
                    onClick={handleClickUpload}
                  >
                    Upload Picture
                  </Button>
                  <Form.Control
                    type="file"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={handleChangeImage}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={9} lg={9} sm={12} xs={12}>
              <Row>
                <Col lg={12} className="mb-3">
                  <p className="text-topic mb-1">username</p>
                  <InputGroup>
                    <InputGroup.Text>
                      <User size={16} />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      name="username"
                      autoComplete="false"
                      required
                      disabled={`${hashUsername === "create" ? "" : 1}`}
                      defaultValue={initialData?.username}
                    />
                  </InputGroup>
                </Col>
                {hashUsername === "create" && (
                  <Col lg={12} className="mb-3">
                    <p className="text-topic mb-1">password</p>
                    <InputGroup>
                      <InputGroup.Text>
                        <Key size={16} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Password"
                        aria-label="Password"
                        type="password"
                        name="password"
                        autoComplete="false"
                        required
                      />
                    </InputGroup>
                  </Col>
                )}

                <Col lg={6} className="mb-3">
                  <p className="text-topic mb-1">Firstname</p>
                  <InputGroup>
                    <Form.Control
                      placeholder="Firstname"
                      aria-label="Firstname"
                      name="firstName"
                      defaultValue={initialData?.firstName}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col lg={6} className="mb-3">
                  <p className="text-topic mb-1">Middlename</p>
                  <InputGroup>
                    <Form.Control
                      placeholder="Middlename"
                      aria-label="Middlename"
                      defaultValue={initialData?.middleName}
                      name="middleName"
                    />
                  </InputGroup>
                </Col>
                <Col lg={6} className="mb-3">
                  <p className="text-topic mb-1">Lastname</p>
                  <InputGroup>
                    <Form.Control
                      placeholder="Lastname"
                      aria-label="Lastname"
                      name="lastName"
                      defaultValue={initialData?.lastName}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col lg={6} className="mb-3">
                  <p className="text-topic mb-1">phone-number</p>
                  <InputGroup>
                    <Form.Control
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      name="phoneNumber"
                      defaultValue={initialData?.phoneNumber}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col lg={12} className="mb-3">
                  <p className="text-topic mb-1">Email</p>
                  <InputGroup>
                    <Form.Control
                      placeholder="Email"
                      aria-label="Email"
                      name="email"
                      defaultValue={initialData?.email}
                      type="email"
                      required
                    />
                  </InputGroup>
                </Col>
                <Col lg={12} className="mb-3">
                  <p className="text-topic mb-1">User Types</p>
                  <Select
                    options={userTypes}
                    className="custom-select"
                    name="userType"
                    onChange={() => handleSelectUserType()}
                    value={selUserType}
                    required
                  />
                </Col>
                <Col sm={12} className="text-end">
                  <Button variant="outline-primary" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </section>
  );
};

export default memo(AccountMaintains);
