import { memo, useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, Image, InputGroup } from "react-bootstrap";
import { useMount } from "hooks";
import { unitPowerTypes } from "const";
import Select from "react-select";
import { Save } from "react-feather";
import { getOrganizations } from "services";

// :: Images :: //
import BlankImage from "assets/images/defaults/blank_500x500.png";

const FormMaintain = ({ onFinish, initial }) => {
  const [oragnizations, setOrganizations] = useState([]);
  const [orgSel, setOrgSel] = useState({});
  const [validated, setValidated] = useState(false);
  const hiddenFileInput = useRef(null);
  const [plantImage, setPlantImage] = useState("");
  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });

    setPlantImage(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      onFinish({ ...formDataObj, plantImage });
    }

    setValidated(true);
  };

  const fetchDataDefault = async () => {
    try {
      const { data: results } = await getOrganizations();

      const optionOrgs = [];

      results?.organizes?.map((res, k) => {
        optionOrgs.push({
          label: res?.orgName,
          value: res?.id,
        });
      });

      setOrganizations(optionOrgs);
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    if (initial) {
      setPlantImage(initial?.data?.plantImage);
      if (initial?.data?.organizeId) {
        console.log(initial?.data?.organizeId);
        oragnizations.filter((item) => {
          if (parseInt(item?.value) === initial?.data?.organizeId) {
            return setOrgSel(item);
          }
        });
      }
    }
  }, [initial, oragnizations]);

  // handle Select Org
  const handleSelectOrg = (value) => {
    setOrgSel(value);
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={3} lg={3} sm={12} xs={12}>
            <Row>
              <Col sm={12}>
                <p className="text-topic mb-1"> picture </p>
                <Image
                  className="w-100 profile-round mb-3"
                  src={plantImage || BlankImage}
                />
                <Button
                  variant="outline-primary w-100"
                  onClick={handleClickUpload}
                >
                  Upload Picture
                </Button>
                <Form.Control
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChangeImage}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </Col>
            </Row>
          </Col>
          <Col xl={9} lg={9} sm={12} xs={12}>
            <Row>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">plant name</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Plant Name"
                    aria-label="Plant Name"
                    name="plantName"
                    defaultValue={initial.data?.plantName}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">description</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Description"
                    aria-label="Description"
                    as="textarea"
                    name="description"
                    defaultValue={initial?.data?.description}
                    rows={4}
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={12} className="mb-3">
                <p className="text-topic mb-1">Organization</p>
                <InputGroup>
                  <Select
                    className="custom-select w-100"
                    name="organizeId"
                    options={oragnizations}
                    value={orgSel}
                    onChange={handleSelectOrg}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">latitude</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Latitude"
                    aria-label="Latitude"
                    step={0.0000001}
                    name="latitude"
                    defaultValue={initial?.data?.latitude}
                    type="number"
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Lonitude</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Lonitude"
                    aria-label="Lonitude"
                    step={0.0000001}
                    name="lonitude"
                    defaultValue={initial?.data?.lonitude}
                    type="number"
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Power Size</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Power Size"
                    aria-label="Power Size"
                    step={0.1}
                    name="powerSize"
                    defaultValue={initial?.data?.powerSize}
                    type="number"
                    required
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Unit</p>
                <InputGroup>
                  <Select
                    className="custom-select w-100"
                    name="unit"
                    defaultValue={unitPowerTypes[0]}
                    options={unitPowerTypes}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-3">
                <p className="text-topic mb-1">Percentage</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Percentage"
                    aria-label="Percentage"
                    step={0.1}
                    name="percentage"
                    defaultValue={initial?.data?.percentage}
                    type="number"
                    max={100}
                    required
                  />
                </InputGroup>
              </Col>
              <Col sm={12} className="text-end">
                <Button variant="outline-primary" type="submit">
                  <Save size={18} className="me-2" />
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(FormMaintain);
